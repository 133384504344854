body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family:"Figtree"
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.logo {
  height: 64px;
  width: 64px;
  margin-left: auto;
  margin-right: auto;
}
table a:hover{
  color:#2c358a;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
.ml-2 {
  margin-left: 2px;
}
#menu ul li {
  color: white;
  background-color: #2c358a;
}
#menu ul li a {
  color: white;
  background-color: #2c358a;
}
#menu ul li:hover {
  background-color: #2c358a;
}
#menu ul li a:hover {

  background-color: #1b2479;
}
#quotelistclient ul li a:hover {
  
  background-color: #1b2479;
}
@font-face {
  font-family: product_sans_bold;
  src: url(FontsFree-Net-ProductSans-Bold.ttf);
}
input::file-selector-button {
  font-size: 0.8rem;
  color: white;
  padding: 0.5rem;
  border: thin solid black;

  background-color: #1b2479;
}

.schcircle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #eea724;
  float: left;
}

.schline {
  margin-top: 5px;
  width: 78%;
  height: 1px;
  background-color: #d5d5d5;
  float: left;
}

.schtable > tr {
  border: none;
  padding: 0px;
}

.contractlist >  tr:hover {
  background-color: #eea724;
  cursor: pointer;
  color: white;
}

.schtable > tr > td {
  border: none;
  padding: 0px;
}

#agpassword > i:hover {
  background-color: #eea724;
  cursor: pointer;
  color: white;
}
.agaddress div:hover {
  background-color: #eea724;
  cursor: pointer;
  color: white;
}
.islink {
  background-color: #2c358a;
  color: white;
}
.islink:hover, .islink:focus, .islink:disabled  {
  background-color: #2c358a;
  color: white;
}

/*  */
.multiselect {
  width: 200px;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  font-weight: bold;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#checkboxes {
  display: none;
  border: 1px #dadada solid;
  background-color: white;
  box-shadow:  0 0 10px  rgba(0,0,0,0.6);
  -moz-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  -webkit-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  -o-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  position: relative;
  z-index: 10;
}

#checkboxes label {
  display: block;
}

#checkboxes label:hover {
  background-color: #1e90ff;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.is-scrollable-header {
  background: white;
  position: sticky;
  top: -20px;
  z-index: 10;
}

.custcheckbox {
  width: 20px;
  height: 20px;
  accent-color: #2c358a;
}

.agcheckbox {
  width: 20px;
  height: 20px;
  accent-color: #eea724;
}

.gtitle {
  background: linear-gradient(to right, #2d348c, #4aa4d9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.percentcontainer {
  width: 250px;
  height: 100px;
  position: relative;
}
.percentbox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

}
.percentoverlay {
  z-index: 9;
  margin: 35%;
  margin-top: 80%;
  width: 20%;
  height: 20%;
  font-size: 150%;
  color: darkgreen;
  font-weight: bold;
  font-family: sans-serif;
}

.agpie {
  --w: 70px;
  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  place-content: center;
  margin: 5px;
  font-size: 24px;
  font-weight: bold;
  color: #060;
  font-family: sans-serif;
}

.agpie:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: 0;
  background: conic-gradient(var(--c) calc(var(--p)*1%), #ddd 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
  mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
}

.transporttooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.transporttooltip .transporttooltiptext {
  visibility: hidden;
  width: 320px;
  background-color: #2d348c;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.transporttooltip:hover .transporttooltiptext {
  visibility: visible;
}

.entitiesV2List >  tr:hover {
  background-color: #eea724;
  cursor: pointer;
  color: white;
}